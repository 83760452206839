import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import contentService from "../../services/api/content-service";
import UserContext from "../../UserContext";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardSubtitle,
  CardImg,
  CardGroup,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import "./articleView.css";
const ArticleView = (props) => {
  const article_id = new URLSearchParams(props.location.search).get("articleId");
  const [formdetails, setFormDetails] = useState({ article_id: article_id });
  const [article, setArticle] = useState(false);
  const [categories, setCategories] = useState(false);
  const [articlesMedia, setArticlesMedia] = useState(false);
  const [totalArticlesMedia, setTotalArticlesMedia] = useState(0);
  const [cdnUrl, setCdnUrl] = useState("");
  const [renderComponent, setRenderComponent] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [totalArticlesVideoMedia, setTotalArticlesVideoMedia] = useState(0);
  const [activeVidMedPage, setActiveVidMedPage] = useState(1);
  const [articlesVideoMedia, setArticlesVideoMedia] = useState(false);
  const [totalArticlesAudioMedia, setTotalArticlesAudioMedia] = useState(0);
  const [activeAuMedPage, setActiveAuMedPage] = useState(1);
  const [articlesAudioMedia, setArticlesAudioMedia] = useState(false)
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const context = useContext(UserContext);
  const records = 10;
  const vidMedRecords = 10;
  const auMedRecords = 10;

  useEffect(() => {
    (async () => {
      let getCdnUrl = await contentService.getCdnUrl();
      if (!getCdnUrl.status && getCdnUrl.error.errorCode === 403) {
        // context.setIsAuthorized(context);
      }
      else if (getCdnUrl.status && getCdnUrl.data) {
        setCdnUrl(getCdnUrl.data);
      }
      let response = await contentService.getArticlesMedia(
        formdetails,
        activePage,
        records
      );
      if (!response.status && response.error.errorCode === 403) {
        // context.setIsAuthorized(context);
      }
      else if (response.status && response.data) {
        setArticlesMedia(response.data);
      }
      response = await contentService.getTotalArticlesMedia(formdetails);
      // if(!response.status && response.error.errorCode === 403){
      //   context.setIsAuthorized(context);
      // }
      if (response.status && response.data) {
        setTotalArticlesMedia(response.data.count);
      }
      setRenderComponent(true);
    })();
  }, [activePage]);

  useEffect(()=>{
    (async()=>{
      let videoData = await contentService.getArticlesVideoMedia(formdetails,
        activeVidMedPage,
        vidMedRecords);
      if(videoData.status && videoData.data){
        console.log("VIdeo Media")
        setArticlesVideoMedia(videoData.data)
      }
      let totalVideos = await contentService.getTotalArticlesVideoMedia(formdetails);
      if(totalVideos.status && totalVideos.data){
        setTotalArticlesVideoMedia(totalVideos.data.count);
      }
    })();
  },[activeVidMedPage])

  useEffect(()=>{
    (async()=>{
      let audioData = await contentService.getArticlesAudioMedia(formdetails,
        activeAuMedPage,
        auMedRecords);
      if(audioData.status && audioData.data){
        setArticlesAudioMedia(audioData.data)
      }
      let totalAudios = await contentService.getTotalArticlesAudioMedia(formdetails);
      if(totalAudios.status && totalAudios.data){
        setTotalArticlesAudioMedia(totalAudios.data.count);
      }
    })();
  },[activeAuMedPage])

  useEffect(() => {
    (async () => {
      let articleData = await contentService.getArticleById(article_id);
      if (!articleData.status && articleData.error.errorCode === 403) {
        // context.setIsAuthorized(context);
      }
      else if (articleData.status) {
        articleData = JSON.parse(JSON.stringify(articleData.data));
        setArticle(articleData);
        let allCategories = articleData.categories;
        let categoryArray = [];
        if (allCategories.length) {
          allCategories.forEach(function (categoryDetails) {
            categoryArray.push({ value: categoryDetails.cat_guid, label: categoryDetails.name });
          });
          setCategories(categoryArray);
        }
      }
    })();
  }, []);
  const reprocessMedia = async () => {
    setShowLoader(true);
    setShowSaveButton(false);
    let formdata = {
      article_id: article_id,
      project_id: article.project_id._id,
    };
    const response = await contentService.processArticleMedia(formdata);
    if (!response.status && response.error.errorCode === 403) {
      context.setIsAuthorized(context);
    }
    else if (response.status) {
      setTimeout(() => {
        setAlert({
          show: true,
          type: "success",
          message: "Media Reprocess job assigned to worker",
        });
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };


  let articlesMediaElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!articlesMedia || !articlesMedia.length) {
    if (!renderComponent) {
      articlesMediaElements.push(
        <td colSpan="8" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      articlesMediaElements.push(
        <td colSpan="8" className="text-center">
          <CardTitle tag="h4">No Article Media Found</CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    articlesMedia.forEach(function (articleDetails) {
      let className = "";
      if (!articleDetails.is_processed) {
        className = "table-warning";
      }
      if (!articleDetails.status) {
        className = "table-danger";
      }
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      articlesMediaElements.push(
        <tr id={"row_" + articleDetails.id} className={className}>
          <td className="text-left">{(activePage - 1) * 10 + i}</td>
          <td className="text-left">{articleDetails.media_type}</td>
          <td className="text-left">
            {articleDetails.url_original ? (
              <a target="_blank" href={articleDetails.url_original}>
                {articleDetails.url_original}
              </a>
            ) : (
              ""
            )}
          </td>
          <td className="text-left">
            {articleDetails.is_processed ? (
              <a
                target="_blank"
                href={`${cdnUrl}${articleDetails.bucket_name}/${articleDetails.s3_keyname}`}
              >{`${cdnUrl}${articleDetails.bucket_name}/${articleDetails.s3_keyname}`}</a>
            ) : (
              "-"
            )}
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleDetails.createdAt}
            </Moment>
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleDetails.updatedAt}
            </Moment>
          </td>
          <td className="text-left">
            {!articleDetails.is_processed ? (
              <i
                className="fa fa-exclamation-triangle"
                style={{ color: "#C00000" }}
              />
            ) : (
              <i class="fa fa-check" aria-hidden="true"></i>
            )}
          </td>
          <td className="text-left">
            {articleDetails.is_failed ? articleDetails.reason : "-"}
          </td>
        </tr>
      );
      if (i == articlesMedia.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == articlesMedia.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    });
  }

  let articlesVideoMediaElements = [];
  let vmedfirstRecord = 0;
  let vmedlastRecord = 0;
  if (!articlesVideoMedia || !articlesVideoMedia.length) {
    if (!renderComponent) {
      articlesVideoMediaElements.push(
        <td colSpan="8" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      articlesVideoMediaElements.push(
        <td colSpan="8" className="text-center">
          <CardTitle tag="h4">No Video Media Found</CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    articlesVideoMedia.forEach(function (articleVideoDetails) {
      let className = "";
      if (!articleVideoDetails.is_processed) {
        className = "table-warning";
      }
      if (!articleVideoDetails.status) {
        className = "table-danger";
      }
      if (i == 1) {
        vmedfirstRecord = (activeVidMedPage - 1) * vidMedRecords + i;
      }
      articlesVideoMediaElements.push(
        <tr id={"row_" + articleVideoDetails.id} className={className}>
          <td className="text-left">{(activeVidMedPage - 1) * 10 + i}</td>
          <td className="text-left">{articleVideoDetails.media_type}</td>
          <td className="text-left">
            {articleVideoDetails.url_original ? (
              <a target="_blank" href={articleVideoDetails.url_original}>
                {articleVideoDetails.url_original}
              </a>
            ) : (
              ""
            )}
          </td>
          <td className="text-left">
            {articleVideoDetails.is_processed && articleVideoDetails.bucket_name && articleVideoDetails.s3_keyname ? (
              <a
                target="_blank"
                href={`${cdnUrl}${articleVideoDetails.bucket_name}/${articleVideoDetails.s3_keyname}`}
              >{`${cdnUrl}${articleVideoDetails.bucket_name}/${articleVideoDetails.s3_keyname}`}</a>
            ) : (
              "-"
            )}
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleVideoDetails.createdAt}
            </Moment>
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleVideoDetails.updatedAt}
            </Moment>
          </td>
          <td className="text-left">
            {!articleVideoDetails.is_processed ? (
              <i
                className="fa fa-exclamation-triangle"
                style={{ color: "#C00000" }}
              />
            ) : (
              <i class="fa fa-check" aria-hidden="true"></i>
            )}
          </td>
          <td className="text-left">
            {articleVideoDetails.is_failed ? articleVideoDetails.reason : "-"}
          </td>
        </tr>
      );
      if (i == articlesVideoMedia.length) {
        vmedlastRecord = (activeVidMedPage - 1) * vidMedRecords + i;
      }
      i++;
      if (i == articlesVideoMedia.length) {
        vmedlastRecord = (activeVidMedPage - 1) * vidMedRecords + i;
      }
    });
  }

  let articlesAudioMediaElements = [];
  let aumedfirstRecord = 0;
  let aumedlastRecord = 0;
  if (!articlesAudioMedia || !articlesAudioMedia.length) {
    if (!renderComponent) {
      articlesAudioMediaElements.push(
        <td colSpan="8" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      articlesAudioMediaElements.push(
        <td colSpan="8" className="text-center">
          <CardTitle tag="h4">No Audio Media Found</CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    articlesAudioMedia.forEach(function (articleAudioDetails) {
      let className = "";
      if (!articleAudioDetails.is_processed) {
        className = "table-warning";
      }
      if (!articleAudioDetails.status) {
        className = "table-danger";
      }
      if (i == 1) {
        aumedfirstRecord = (activeAuMedPage - 1) * auMedRecords + i;
      }
      articlesAudioMediaElements.push(
        <tr id={"row_" + articleAudioDetails.id} className={className}>
          <td className="text-left">{(activeAuMedPage - 1) * 10 + i}</td>
          <td className="text-left">{articleAudioDetails.media_type}</td>
          <td className="text-left">
            {articleAudioDetails.url_original ? (
              <a target="_blank" href={articleAudioDetails.url_original}>
                {articleAudioDetails.url_original}
              </a>
            ) : (
              ""
            )}
          </td>
          <td className="text-left">
            {articleAudioDetails.is_processed && articleAudioDetails.bucket_name && articleAudioDetails.s3_keyname ? (
              <a
                target="_blank"
                href={`${cdnUrl}${articleAudioDetails.bucket_name}/${articleAudioDetails.s3_keyname}`}
              >{`${cdnUrl}${articleAudioDetails.bucket_name}/${articleAudioDetails.s3_keyname}`}</a>
            ) : (
              "-"
            )}
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleAudioDetails.createdAt}
            </Moment>
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleAudioDetails.updatedAt}
            </Moment>
          </td>
          <td className="text-left">
            {!articleAudioDetails.is_processed ? (
              <i
                className="fa fa-exclamation-triangle"
                style={{ color: "#C00000" }}
              />
            ) : (
              <i class="fa fa-check" aria-hidden="true"></i>
            )}
          </td>
          <td className="text-left">
            {articleAudioDetails.is_failed ? articleAudioDetails.reason : "-"}
          </td>
        </tr>
      );
      if (i == articlesAudioMedia.length) {
        aumedlastRecord = (activeAuMedPage - 1) * auMedRecords + i;
      }
      i++;
      if (i == articlesAudioMedia.length) {
        aumedlastRecord = (activeAuMedPage - 1) * auMedRecords + i;
      }
    });
  }
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setActivePage(pageNumber);
  };

  const handleVidMedPageChange = (pageNumber) => {
    setActiveVidMedPage(pageNumber);
  }

  const handleAuMedPageChange = (pageNumber) => {
    setActiveAuMedPage(pageNumber);
  }
  return (
    <div className="content">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <Row>
                  <Col></Col>
                  <Col sm="5" align="center"><CardTitle tag="h4">Article View</CardTitle></Col>
                  <Col sm="3">
                    {showSaveButton ?
                      <Button
                        className="btn-smb"
                        type="button"
                        onClick={reprocessMedia}
                      >
                        Reprocess Media
                      </Button>
                      :
                      ""}
                    {alert.show ?
                      <Alert color={alert.type}>
                        {alert.message}
                      </Alert>
                      :
                      ""}
                  </Col>
                  <Col sm="1">
                    {showLoader ?
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      : ""
                    }
                  </Col>
                </Row>
              </CardHeader>
              <hr />
              <CardBody>
                <Row>
                  <Row>
                    <Col md="4">Publisher Name : {(article.project_id && article.project_id.publishers_id) ? article.project_id.publishers_id.name : ""}</Col>
                    <Col md="4">Project : {article.project_id ? article.project_id.name : ""}</Col>
                    <Col md="4">Article GUID : {article.guid}</Col>
                  </Row>
                  <br></br><br></br>
                  <Row>
                    <Col md="4">Author : {article.author}</Col>
                    <Col md="4">Type : {article.type}</Col>
                    <Col md="4">Published On : <Moment format="dddd DD MMM YYYY hh:mm:ss a">
                      {article.article_published_on}
                    </Moment>
                    </Col>
                  </Row>
                  <br></br><br></br>
                  <Row>
                    <Col md="4">Modified On : <Moment format="dddd DD MMM YYYY hh:mm:ss a">
                      {article.article_modified_on}
                    </Moment>
                    </Col>
                    <Col md="4">Category : {categories ? categories.map((category) => (
                      <Badge color="primary" pill>{category.label}</Badge>
                    )) : ""}
                    </Col>
                    <Col md="4">Status : {article.status ?
                      <Badge color="success" pill>
                        Active
                      </Badge>
                      :
                      <Badge color="danger" pill>
                        inactive
                      </Badge>
                    }
                    </Col>
                  </Row>
                  <br></br><br></br>
                  <Row>
                    <Col md="4">Article Slug : {article.slug}</Col>
                    <Col md="4">Article Link : <a href={article.share_url} target="_blank">{article.share_url}</a></Col>
                  </Row>
                </Row>
              </CardBody>
            </Card>

            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Article Media</CardTitle>
              </CardHeader>
              <CardBody>
                {totalArticlesMedia > 0 ? 
                  <Row>
                    <Col md="6">
                      <div style={{ float: "right"}}>
                        <b>
                          {firstRecord} - {lastRecord} of {totalArticlesMedia}
                        </b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "left" }}>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalArticlesMedia}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                : null}
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Media Type</th>
                      <th className="text-left">Original url</th>
                      <th className="text-left"> url</th>
                      <th className="text-left">Created On</th>
                      <th className="text-left">Modified On</th>

                      <th className="text-left">Is Processed</th>
                      <th className="text-left">Reason</th>
                    </tr>
                  </thead>
                  <tbody>{articlesMediaElements}</tbody>
                </Table>
              </CardBody>
            </Card>

            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Video Media</CardTitle>
              </CardHeader>
              <CardBody>
                {totalArticlesVideoMedia > 0 ? 
                  <Row>
                    <Col md="6">
                      <div style={{ float: "right"}}>
                        <b>
                          {vmedfirstRecord} - {vmedlastRecord} of {totalArticlesVideoMedia}
                        </b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "left" }}>
                        <Pagination
                          activePage={activeVidMedPage}
                          itemsCountPerPage={vidMedRecords}
                          totalItemsCount={totalArticlesVideoMedia}
                          pageRangeDisplayed={5}
                          onChange={handleVidMedPageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                : null}
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Media Type</th>
                      <th className="text-left">Original url</th>
                      <th className="text-left"> url</th>
                      <th className="text-left">Created On</th>
                      <th className="text-left">Modified On</th>

                      <th className="text-left">Is Processed</th>
                      <th className="text-left">Reason</th>
                    </tr>
                  </thead>
                  <tbody>{articlesVideoMediaElements}</tbody>
                </Table>
              </CardBody>
            </Card>

            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Audio Media</CardTitle>
              </CardHeader>
              <CardBody>
                {totalArticlesAudioMedia > 0 ? 
                  <Row>
                    <Col md="6">
                      <div style={{ float: "right"}}>
                        <b>
                          {aumedfirstRecord} - {aumedlastRecord} of {totalArticlesAudioMedia}
                        </b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "left" }}>
                        <Pagination
                          activePage={activeAuMedPage}
                          itemsCountPerPage={auMedRecords}
                          totalItemsCount={totalArticlesAudioMedia}
                          pageRangeDisplayed={5}
                          onChange={handleAuMedPageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                : null}
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Media Type</th>
                      <th className="text-left">Original url</th>
                      <th className="text-left"> url</th>
                      <th className="text-left">Created On</th>
                      <th className="text-left">Modified On</th>

                      <th className="text-left">Is Processed</th>
                      <th className="text-left">Reason</th>
                    </tr>
                  </thead>
                  <tbody>{articlesAudioMediaElements}</tbody>
                </Table>
              </CardBody>
            </Card>
            <Card className="card-signup text-center">
          <CardHeader>
            <CardTitle tag="h5">{article.title}</CardTitle>
          </CardHeader>
          <div className="textareaDiv">
            {article ?
              <Input
                name="article_body"
                id="article_body"
                type="textarea"
                value={article.body}
                style={{ "min-height": "300px", "padding": "15px" }}
                disabled
              />
              : ""}
          </div>
        <CardFooter></CardFooter>
      </Card>
      <Card className="card-signup ">
        <CardHeader className="text-center">
                <CardTitle tag="h5">Custom Meta Fields</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="textareaDiv">
                  {article.custom_meta_data ?
                    <>
                      {Object.entries(article.custom_meta_data).map(([key, value]) => (
                        <Row>
                          <Col md='5' className="text-left">{key}</Col>
                          <Col md='7' className="text-left" >{value}</Col>
                        </Row>
                      ))}
                    </>
                    : ""}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row >
      </Container >
  <div
    className="full-page-background"
    style={{
      backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
    }}
  />
    </div >
  );
};

// }

export default ArticleView;
