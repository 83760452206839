import React, { useEffect, useState, useContext  } from "react";
import Select from 'react-select'; 
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import jobsService from "../../services/api/jobs-service";
import {useHistory} from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
  } from "reactstrap";

import UserContext from "../../UserContext";

const ScheduleNewJob = (props)=>{
    const history = useHistory();
    const [jobTypeSchedule, setJobTypeSchedule] = useState(false);
    const [fieldStates,setFieldStates] = useState({});
    const [showLoader, setShowLoader]   = useState(false);
    const [showSaveButton, setShowSaveButton]   = useState(true);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
        type:""
    });
    const context = useContext(UserContext);

    const [formData, setFormData] = useState({
        jobData: "",
        jobName: "",
        scheduleTime:"",
        endDate:"",
        frequency:"",
        jobType:""
    })

    const jobType =[
        {value:"instant", label:"Instant"},
        {value:"schedule", label:"Schedule"},
    ]


    const jobNameOptions =[
        {value:"dailyReports", label:"Daily report"},
        {value:"contractManagement", label:"Contract  Management"},
        {value:"updateContractQuota" , label: "Update Contract Quota"},
        {value:"trackStats", label:"Track Stats"},
        {value : "contractExpiryAlert" , label:"Contract Expiry Alert"},
        {value : "bigqueryDailyReportSync" , label:"Bigquery Daily Report Sync"},
        {value: "validateCname" , label: "Validate Cname"},
        {value: "trendingArticle", label: "Process Trending Articles"},
        {value: "autonotificationDailyReport", label: "Auto-notifications Daily Report"},
        {value: "ctvVideoImport", label: "CTV Video Import"},
        {value: "cflogHealthCheck", label: "Trending Article CFLog HealthCheck"}
    ]
    
    const handleSubmit = async(event)=>{

        event.preventDefault();
        console.log(validateForm())
        if(!validateForm()) return;
        // return;
        setShowLoader(true);
        setShowSaveButton(false);
        const response = await jobsService.scheduleJob(formData);
        if(!response.status && response.error.errorCode === 403){
            setAlert({show:false});
            context.setIsAuthorized(context);
        }
        else if(response.status && response.data.isScheduled){
            setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true);
            setAlert({show:true,type:'success',message:'Job Scheduled successfully'});
            },1000);
        }else{
            setTimeout(()=>{
            setShowLoader(false)
            setShowSaveButton(true)
            setAlert({show:true,type:'danger',message:'Unable to Schedule job, Please try again.'});
            },1000);
        }

      setTimeout(()=>{
        setAlert({show:false,type:'',message:''});
      },3000);

      setTimeout(()=>{
        window.location.reload();
      },2000);

        console.log(response)
    }

    const handleChange = async(event)=>{
        setFormData({
            ...formData,
            [event.target.name]:event.target.value
        })
    }

    const onJobTypeChange = async(event)=>{

        console.log(event)
        if(event.value ==="schedule"){
            setJobTypeSchedule(true);
        }
        setFormData({
            ...formData,
            jobType: event.value
        })
    }

    const handleScheduleTimeChange = async(event)=>{
        setFormData({
            ...formData,
            scheduleTime: moment(event._d).utc().toString()
        })
    }

    const handleEndTimeChange = async(event)=>{
        setFormData({
            ...formData,
            endDate: moment(event._d).utc().toString()
        })
    }

    const handleJobNameChange = (event)=>{
        setFormData({
            ...formData,
            jobName:event.value
        })
    }

    const disablePastDates = (current) =>{
        const previousDate =  moment().subtract(1, 'day');
        return current.isAfter(previousDate);
    }

    const validateForm =  () => {
        let validations = true;
        if(formData.jobName===''){
            fieldStates['error_jobName'] = 'has-danger';
            fieldStates['error_message_jobName'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_jobName'] = 'has-success';
            fieldStates['error_message_jobName'] = '';
        }
        if(formData.jobType===''){
            fieldStates['error_jobType'] = 'has-danger';
            fieldStates['error_message_jobType'] = 'This field is required.';
            validations = false;
        }else{
            fieldStates['error_jobType'] = 'has-success';
            fieldStates['error_message_jobType'] = '';
        }
        if(formData.jobType=="schedule"){
            if(formData.scheduleTime===''){
                fieldStates['error_scheduleTime'] = 'has-danger';
                fieldStates['error_message_scheduleTime'] = 'This field is required.';
                validations = false;
            }else{
                fieldStates['error_scheduleTime'] = 'has-success';
                fieldStates['error_message_scheduleTime'] = '';
            }
            if(formData.endDate===''){
                fieldStates['error_endDate'] = 'has-danger';
                fieldStates['error_message_endDate'] = 'This field is required.';
                validations = false;
            }else{
                fieldStates['error_endDate'] = 'has-success';
                fieldStates['error_message_endDate'] = '';
            } 
            if(formData.frequency===''){
                fieldStates['error_frequency'] = 'has-danger';
                fieldStates['error_message_frequency'] = 'This field is required.';
                validations = false;
            }else{
                fieldStates['error_frequency'] = 'has-success';
                fieldStates['error_message_frequency'] = '';
            } 
        }
        setFieldStates({
        ...fieldStates,
        fieldStates
        });
        return validations;
    }

    return (
        <>
         <div className="content">
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Label sm="2">Name<span class="text-danger"> * </span></Label>
                        <FormGroup className={fieldStates["error_jobName"]}>
                        <Col sm="7">
                        <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Select Job .."
                                name="jobName"
                                id="jobName"
                                options={jobNameOptions}
                                onChange={handleJobNameChange}
                            />
                        </Col>
                        {fieldStates["error_jobName"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_jobName"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        
                    </Row>
                    
                    <Row>
                        <Label sm="2">Job Data</Label>
                        <FormGroup >
                        <Col sm="7">
                            <Input
                                name="jobData"
                                type="textarea"
                                onChange={handleChange}
                                // value={JSON.parse(formData.jobData)}
                            />
                        </Col>
                        </FormGroup>
                        
                    </Row>


                    <Row>
                        <Label sm="2">Job type <span class="text-danger"> * </span></Label>
                        <FormGroup className={fieldStates["error_jobType"]}>
                        <Col sm="7">
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Select Frequency .."
                                name="jobType"
                                id="jobType"
                                options={jobType}
                                onChange={onJobTypeChange}
                            />
                        </Col>
                        {fieldStates["error_jobType"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_jobType"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        
                    </Row>

                    {jobTypeSchedule? <>
                    
                        <Row>
                        <Label sm="2">Schedule Time <span class="text-danger"> * </span></Label>
                        <FormGroup className={fieldStates["error_scheduleTime"]}>
                        <Col sm="7">
                            <ReactDatetime
                                
                                name="scheduleTime"
                                id="scheduleTime"
                                isValidDate={disablePastDates}
                                onChange={handleScheduleTimeChange}
                                dateFormat ={"DD/MM/YYYY"}
                                closeOnSelect
                            />
                        </Col>
                        {fieldStates["error_scheduleTime"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_scheduleTime"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        
                    </Row>

                    <Row>
                        <Label sm="2">End Date <span class="text-danger"> * </span></Label>
                        <FormGroup className={fieldStates["error_endDate"]}>
                        <Col sm="7">
                            <ReactDatetime
                                
                                name="endDate"
                                id="endDate"
                                isValidDate={disablePastDates}
                                onChange={handleEndTimeChange}
                                dateFormat ={"DD/MM/YYYY"}
                                closeOnSelect
                              />
                        </Col>
                        {fieldStates["error_endDate"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_endDate"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        
                    </Row>

                    <Row>
                        <Label sm="2">Frequency (in minutes) <span class="text-danger"> * </span></Label>
                        <FormGroup className={fieldStates["error_frequency"]}>
                        <Col sm="7">
                            <input
                                
                                name="frequency"
                                id="frequency"
                                type="number"
                                min="0"
                                onChange={handleChange}
                            />
                        </Col>
                        {fieldStates["error_frequency"] ===
                            "has-danger" ? (
                                <label className="error">
                                    {
                                        fieldStates[
                                            "error_message_frequency"
                                        ]
                                    }
                                </label>
                            ) : null}
                        </FormGroup>
                        
                    </Row>
                        
                    
                    
                    </>:""}
                    {showSaveButton?
                    <>
                    <Button type="submit">Schedule Job</Button>
                    <Button
                            // className="btn-round"
                            color="warning"
                            type="button"
                            onClick={(event) => {history.push(`/jobs/dashboard`)}}
                        >
                                Cancel
                    </Button></> :""} 
                </Form>

                {
                    alert.show? <Alert color={alert.type}>
                        {alert.message}
                    </Alert> :''
                }
                {showLoader?<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>:''}
            </Container>
            </div>
        </>
    )
}

export default ScheduleNewJob;